import { BehaviorSubject, combineLatest, iif, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  first,
  map,
  mergeMap,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  ComputeToastComponent,
  ComputeToastModel,
} from 'ssotool-app/+roadmap/modules/toasts/compute-toast';
import { Coerce, ExecStatusChecker } from 'ssotool-shared';
import { SSOToolRoutePathService } from 'ssotool-shared/services';

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { ResultFacadeService } from '../result/result-facade.service';
import { RoadmapVariationFacadeService } from '../roadmap-variation';
import { RoadmapVariationActions } from '../roadmap-variation/roadmap-variation.actions';
import { RoadmapApiService } from './roadmap-api.service';
import { RoadmapFacadeService } from './roadmap-facade.service';
import { RoadmapEntityActions } from './roadmap.actions';
import { PortfolioRoadmap, RoadmapCampaign } from './roadmap.model';
import { ToastService } from 'ssotool-app/shared/services/toast/toast.service';
import { MeteomaticsToastComponent } from 'ssotool-app/shared/component/custom-toast/meteomatics-toast/meteomatics-toast.component';

@Injectable()
export class RoadmapEffects {
  constructor(
    private act: Actions,
    private api: RoadmapApiService,
    private fac: RoadmapFacadeService,
    private toast: ToastService,
    private router: Router,
    private routePath: SSOToolRoutePathService,
    private statusChecker: ExecStatusChecker,
    private resultFacade: ResultFacadeService,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
    private toastService: HotToastService,
    private variationFacade: RoadmapVariationFacadeService,
  ) {}

  getRoadmapSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.getRoadmapAction),
      mergeMap((action) =>
        this.api.getRoadmap(action.clientId, action.roadmapId).pipe(
          map((data: PortfolioRoadmap) =>
            RoadmapEntityActions.getRoadmapOKAction({ data, ...action }),
          ),
          catchError(() =>
            of(RoadmapEntityActions.getRoadmapNOKAction(action)),
          ),
        ),
      ),
    );
  }

  getRoadmapOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.getRoadmapOKAction),
      tap((action) => {
        this.variationFacade.getList(action);
        if (action?.isSuccessful) {
          this.variationFacade
            .getVariationAccessed(
              action.roadmapId,
              Coerce.toEmptyObject(action.data.runSettings).variationId,
            )
            .pipe(first())
            .subscribe(
              (accessed) =>
                accessed &&
                this.resultFacade.get({
                  clientId: action.clientId,
                  roadmapId: action.roadmapId,
                  variationId: Coerce.toEmptyObject(action.data.runSettings)
                    .variationId,
                }),
            );
        }
      }),
      map((action) =>
        RoadmapEntityActions.fetchRoadmapCampaigns({
          clientId: action.clientId,
          roadmapId: action.roadmapId,
        }),
      ),
    );
  }

  getRoadmapNOKSideEffect() {
    return this.act.pipe(ofType(RoadmapEntityActions.getRoadmapNOKAction));
  }

  getAllRoadmapSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.getAllRoadmapAction),
      mergeMap((action) =>
        this.api.getAllRoadmaps(action.clientId).pipe(
          map((roadmaps) =>
            RoadmapEntityActions.getAllRoadmapOKAction({
              data: roadmaps,
              clientId: action.clientId,
              message: 'Get roadmap list success!',
            }),
          ),
          catchError((err) =>
            of(
              RoadmapEntityActions.getAllRoadmapNOKAction({
                clientId: action.clientId,
                message: (err.error || {}).error || 'Get roadmap list error!',
              }),
            ),
          ),
        ),
      ),
    );
  }

  getAllRoadmapOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.getAllRoadmapOKAction),
      tap(({ data }) =>
        data.forEach((roadmap) => {
          this.variationFacade.getList({
            clientId: roadmap.clientId,
            roadmapId: roadmap.roadmapId,
          });

          this.fac.fetchRoadmapCampaigns(roadmap.clientId, roadmap.roadmapId);
        }),
      ),
    );
  }

  getAllRoadmapNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.getAllRoadmapNOKAction),
      tap((action) =>
        this.toast.showToast(
          action.message,
          'action',
          undefined,
          of({}).pipe(tap(() => this.fac.getAllRoadmap(action.clientId))),
        ),
      ),
    );
  }

  createSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.createRoadmapAction),
      mergeMap((action) =>
        this.api.createRoadmap(action).pipe(
          map((entity) =>
            RoadmapEntityActions.createRoadmapOKAction({
              data: entity,
              params: action,
              message: 'Create roadmap success!',
            }),
          ),
          catchError((err) =>
            of(
              RoadmapEntityActions.createRoadmapNOKAction({
                params: action,
                message: (err.error || {}).error || 'Create roadmap error!',
              }),
            ),
          ),
        ),
      ),
    );
  }

  createOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.createRoadmapOKAction),
      tap(({ data }) =>
        this.variationFacade.getList({
          clientId: data.clientId,
          roadmapId: data.roadmapId,
        }),
      ),
      tap(
        (action) =>
          action.params.toCompute &&
          this.fac.computeRoadmap({
            ...action.params,
            roadmapId: action.data.id,
          }),
      ),
      map((action) => {
        if (action.params.isBaseline) {
          return RoadmapEntityActions.setToBaselineAction({
            data: [],
            clientId: action.data.clientId,
            roadmapId: action.data.id,
            isBaseline: true,
            redirectToPage: true,
          });
        } else {
          this.router.navigate(
            this.routePath.viewRoadmap(
              action.data.clientId,
              action.data.id,
              action.data.variationId,
            ),
          );
          return RoadmapEntityActions.doNothingAction();
        }
      }),
    );
  }

  createNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.createRoadmapNOKAction),
      tap((action) =>
        this.toast.showToast(
          action.message,
          'action',
          undefined,
          of({}).pipe(tap(() => this.fac.createRoadmap(action.params))),
        ),
      ),
    );
  }

  updateSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.updateRoadmapAction),
      mergeMap((action) =>
        this.api.updateRoadmap(action).pipe(
          map((entity) =>
            RoadmapEntityActions.updateRoadmapOKAction({
              data: entity,
              params: action,
              message: 'Update roadmap success!',
            }),
          ),
          catchError((err) =>
            of(
              RoadmapEntityActions.updateRoadmapNOKAction({
                params: action,
                message: (err.error || {}).error || 'Update roadmap error!',
              }),
            ),
          ),
        ),
      ),
    );
  }

  updateOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.updateRoadmapOKAction),
      tap(({ params }) =>
        this.variationFacade.getList({
          clientId: params.clientId,
          roadmapId: params.roadmapId,
        }),
      ),
      tap(
        (action) =>
          action.params.toCompute && this.fac.computeRoadmap(action.params),
      ),
    );
  }

  updateNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.updateRoadmapNOKAction),
      tap((action) =>
        this.toast.showToast(
          action.message,
          'action',
          undefined,
          of({}).pipe(tap(() => this.fac.updateRoadmap(action.params))),
        ),
      ),
    );
  }

  computeSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.computeRoadmapAction),
      mergeMap((action) =>
        this.api.computeRoadmap(action).pipe(
          map((data) =>
            RoadmapEntityActions.computeRoadmapOKAction({
              data,
              message: 'Roadmap computation started.',
            }),
          ),
          catchError((err) => {
            //keep for now. remove after e2e test
            return of(
              RoadmapEntityActions.computeRoadmapNOKAction({
                params: action,
                message: (err.error || {}).error || 'Update roadmap error!',
                status: err.status,
              }),
            );
          }),
        ),
      ),
    );
  }

  computeOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.computeRoadmapOKAction),
      tap((action) => this.toast.showToast(action.message, 'info')),
      tap(({ data }) =>
        this.variationFacade.getList({
          clientId: data.clientId,
          roadmapId: data.roadmapId,
        }),
      ),
      map(({ data }) =>
        RoadmapEntityActions.fetchRoadmapCampaigns({
          clientId: data.clientId,
          roadmapId: data.roadmapId,
        }),
      ),
    );
  }

  computeNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.computeRoadmapNOKAction),
      tap((action) => {
        if (action.status === 422) {
          this.toastService.show(MeteomaticsToastComponent, {
            autoClose: false,
            data: {
              message: this.translateService.instant(
                'Roadmap.messages.noMeteo',
              ),
              labelAction: 'Proceed',
              title: 'Renewable Production Profiles',
              action: of({}).pipe(
                tap(() => {
                  this.fac.computeRoadmap({
                    ...action.params,
                    isTriggerMeteo: true,
                  });
                }),
              ),
            },
          });
        } else {
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(tap(() => this.fac.computeRoadmap(action.params))),
          );
        }
      }),
    );
  }

  stopComputeSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.stopComputeRoadmapAction),
      mergeMap((action) =>
        this.api.stopComputeRoadmap(action.clientId, action.roadmapId).pipe(
          map(() =>
            RoadmapEntityActions.stopComputeRoadmapOKAction({
              params: action,
              message: 'Stop roadmap computation success!',
            }),
          ),
          catchError((err) =>
            iif(
              () => err.status === 409,
              of(
                RoadmapEntityActions.stopComputeRoadmapPartialAction({
                  roadmapId: action.roadmapId,
                  message:
                    'Roadmap computation is almost finished. Cancellation is not processed.',
                }),
              ),
              of(
                RoadmapEntityActions.stopComputeRoadmapNOKAction({
                  params: action,
                  message:
                    (err.error || {}).error ||
                    'Stop roadmap computation error!',
                }),
              ),
            ),
          ),
        ),
      ),
    );
  }

  stopComputeOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.stopComputeRoadmapOKAction),
      tap((action) => this.toast.showToast(action.message, 'success')),
      tap(({ params }) =>
        this.variationFacade.getList({
          clientId: params.clientId,
          roadmapId: params.roadmapId,
        }),
      ),
    );
  }

  stopComputePartialSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.stopComputeRoadmapPartialAction),
      tap((action) => this.toast.showToast(action.message, 'info')),
    );
  }

  stopComputeNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.stopComputeRoadmapNOKAction),
      tap((action) =>
        this.toast.showToast(
          action.message,
          'action',
          undefined,
          of({}).pipe(
            tap(() =>
              this.fac.stopComputeRoadmap(
                action.params.clientId,
                action.params.roadmapId,
              ),
            ),
          ),
        ),
      ),
    );
  }

  deleteSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.deleteRoadmapAction),
      mergeMap((action) =>
        this.api.deleteRoadmap(action).pipe(
          map(() =>
            RoadmapEntityActions.deleteRoadmapOKAction({
              params: action,
              message: 'Delete roadmap success!',
            }),
          ),
          catchError((err) =>
            of(
              RoadmapEntityActions.deleteRoadmapNOKAction({
                params: action,
                message: (err.error || {}).error || 'Delete roadmap error!',
              }),
            ),
          ),
        ),
      ),
    );
  }

  deleteOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.deleteRoadmapOKAction),
      tap((action) => this.toast.showToast(action.message, 'success')),
    );
  }

  deleteNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.deleteRoadmapNOKAction),
      tap((action) =>
        this.toast.showToast(
          action.message,
          'action',
          undefined,
          of({}).pipe(tap(() => this.fac.deleteRoadmap(action.params))),
        ),
      ),
    );
  }

  computeMultipleSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.computeMultipleRoadmapAction),
      mergeMap((action) =>
        this.api
          .computeMultipleRoadmap(
            action.clientId,
            action.roadmapIds,
            action.proceed,
          )
          .pipe(
            map((data) =>
              RoadmapEntityActions.computeMultipleRoadmapOKAction({
                data,
                message: 'Multiple roadmap computation started.',
              }),
            ),
            catchError((err) =>
              of(
                RoadmapEntityActions.computeMultipleRoadmapNOKAction({
                  params: action,
                  message:
                    (err.error || {}).error ||
                    'Multiple roadmap computation error!',
                  status: err.status,
                }),
              ),
            ),
          ),
      ),
    );
  }

  computeMultipleOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.computeMultipleRoadmapOKAction),
      tap(({ data }) =>
        data.roadmapIds.forEach((roadmapId) =>
          this.variationFacade.getList({
            clientId: data.clientId,
            roadmapId,
          }),
        ),
      ),
    );
  }

  computeMultipleNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.computeMultipleRoadmapNOKAction),
      tap((action) => {
        if (action.status === 422) {
          this.toastService.show(MeteomaticsToastComponent, {
            data: {
              message: this.translateService.instant(
                'Roadmap.messages.noMeteo',
              ),
              labelAction: 'Proceed',
              title: 'Renewable Production Profiles',
              action: of({}).pipe(
                tap(() => {
                  this.fac.computeMultipleRoadmap(
                    action.params.clientId,
                    action.params.roadmapIds,
                    true,
                  );
                }),
              ),
            },
          });
        } else {
          this.toast.showToast(
            action.message,
            'action',
            undefined,
            of({}).pipe(
              tap(() =>
                this.fac.computeMultipleRoadmap(
                  action.params.clientId,
                  action.params.roadmapIds,
                ),
              ),
            ),
          );
        }
      }),
    );
  }

  setToBaselineSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.setToBaselineAction),
      mergeMap((action) =>
        this.api.updateRoadmap(action).pipe(
          map((entity) =>
            RoadmapEntityActions.setToBaselineOKAction({
              data: entity,
              params: action,
              message: 'Set roadmap as baseline success!',
            }),
          ),
          catchError((err) =>
            of(
              RoadmapEntityActions.setToBaselineNOKAction({
                params: action,
                message:
                  (err.error || {}).error || 'Set roadmap as baseline error!',
              }),
            ),
          ),
        ),
      ),
    );
  }

  setToBaselineOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.setToBaselineOKAction),
      tap((action) => this.toast.showToast(action.message, 'success')),
      tap((action) => {
        if (action.params?.redirectToPage) {
          this.router.navigate(
            this.routePath.viewRoadmap(
              action.data.clientId,
              action.data.id,
              action.data.variationId,
            ),
          );
        }
      }),
    );
  }

  setToBaselineNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.setToBaselineNOKAction),
      tap((action) =>
        this.toast.showToast(
          action.message,
          'action',
          undefined,
          of({}).pipe(tap(() => this.fac.setToBaseline(action.params))),
        ),
      ),
    );
  }

  duplicateRoadmapsSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.duplicateRoadmapsAction),
      mergeMap((action) =>
        this.api.duplicateRoadmaps(action.clientId, action.roadmapIds).pipe(
          map((response) =>
            RoadmapEntityActions.duplicateRoadmapsOKAction({
              data: response,
              roadmapIds: action.roadmapIds,
            }),
          ),
          catchError((err) =>
            of(
              RoadmapEntityActions.duplicateRoadmapsNOKAction({
                ...action,
                message: (err.error || {}).error || 'Duplicate roadmaps error!',
              }),
            ),
          ),
        ),
      ),
    );
  }

  duplicateRoadmapsOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.duplicateRoadmapsOKAction),
      tap(({ data }) =>
        data.forEach((roadmap: PortfolioRoadmap) =>
          this.variationFacade.getList({
            clientId: roadmap.clientId,
            roadmapId: roadmap.roadmapId,
          }),
        ),
      ),
    );
  }

  duplicateRoadmapsNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.duplicateRoadmapsNOKAction),
      tap((action) =>
        this.toast.showToast(
          action.message,
          'action',
          undefined,
          of({}).pipe(
            tap(() =>
              this.fac.duplicateRoadmaps(action.clientId, action.roadmapIds),
            ),
          ),
        ),
      ),
    );
  }

  reloadSingleRoadmapSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.reloadSingleRoadmapAction),
      mergeMap((action) =>
        iif(
          () =>
            this.statusChecker.isSuccessful(action.status) ||
            this.statusChecker.isError(action.status),
          of(action).pipe(
            map((action) =>
              RoadmapEntityActions.reloadSingleRoadmapOKAction({
                data: action,
                isSuccessful: this.statusChecker.isSuccessful(action.status),
              }),
            ),
          ),
          of(action).pipe(
            map((action) => {
              return RoadmapEntityActions.reloadRoadmapSilentlyAction({
                roadmapId: action.roadmapId,
                status: action.status,
                feStatus: this.statusChecker.mapToFrontend(action.status),
              });
            }),
          ),
        ),
      ),
      catchError((err) =>
        of(
          RoadmapEntityActions.reloadSingleRoadmapNOKAction({
            message:
              (err.error || {}).error || 'Reloading roadmap results error!',
          }),
        ),
      ),
    );
  }

  computeCanLaunchRoadmapSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.computeCanLaunchRoadmapAction),
      mergeMap((action) =>
        of(action).pipe(
          filter(
            () =>
              this.statusChecker.isSuccessful(action.status) ||
              this.statusChecker.isError(action.status),
          ),
          map((action) =>
            RoadmapEntityActions.computeCanLaunchRoadmapOKAction({
              data: action,
              isSuccessful: this.statusChecker.isSuccessful(action.status),
            }),
          ),
          catchError(() =>
            of(RoadmapEntityActions.computeCanLaunchRoadmapNOKAction()),
          ),
        ),
      ),
    );
  }

  reloadSingleRoadmapOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.reloadSingleRoadmapOKAction),
      tap((action) => {
        this.fac.get(action.data.clientId, action.data.roadmapId);

        if (action.isSuccessful) {
          this.toastService.success<ComputeToastModel>(ComputeToastComponent, {
            data: action.data,
            duration: 15000,
          });
        } else {
          this.toastService.error<ComputeToastModel>(ComputeToastComponent, {
            data: action.data,
          });
        }
      }),
    );
  }

  reloadSingleRoadmapNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.reloadSingleRoadmapNOKAction),
      tap((action) => this.toastService.error(action.message)),
    );
  }

  reloadMultipleRoadmapSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.reloadMultipleRoadmapAction),
      map((action) => ({
        ...action,
        feStatus: this.statusChecker.mapToFrontend(action.status),
      })),
      mergeMap((action) =>
        iif(
          () =>
            this.statusChecker.isSuccessful(action.status) ||
            this.statusChecker.isError(action.status),
          of(action).pipe(
            map(({ clientId, roadmapIds }) =>
              RoadmapEntityActions.reloadMultipleRoadmapOKAction({
                clientId,
                roadmapIds,
                message: 'Reloading multiple roadmap success!',
                isSuccessful: this.statusChecker.isSuccessful(action.status),
              }),
            ),
          ),
          of(action).pipe(
            map(({ roadmapIds, status, feStatus }) =>
              RoadmapVariationActions.reloadMultipleRoadmapSilentlyAction({
                roadmapIds,
                beStatus: status,
                feStatus,
              }),
            ),
          ),
        ),
      ),
      catchError((err) =>
        of(
          RoadmapEntityActions.reloadAffectedRoadmapNOKAction({
            message:
              (err.error || {}).error || 'Reloading multiple roadmap error!',
          }),
        ),
      ),
    );
  }

  reloadMultipleRoadmapOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.reloadMultipleRoadmapOKAction),
      tap((action) => {
        action.roadmapIds.map((roadmapId) => {
          this.fac.get(action.clientId, roadmapId, action.isSuccessful);
          if (action.isSuccessful) {
            this.toastService.success<ComputeToastModel>(
              ComputeToastComponent,
              {
                data: { clientId: action.clientId, roadmapId },

                duration: 15000,
              },
            );
          } else {
            this.toastService.error<ComputeToastModel>(ComputeToastComponent, {
              data: { clientId: action.clientId, roadmapId },
            });
          }
        });
      }),
    );
  }

  reloadMultipleRoadmapNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.reloadMultipleRoadmapNOKAction),
      tap((action) => this.toastService.error(action.message)),
    );
  }

  reloadAffectedRoadmapSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.reloadAffectedRoadmapAction),
      tap((action) => {
        action.roadmapIds.forEach((roadmapId) => {
          this.variationFacade.getList({
            clientId: action.clientId,
            roadmapId,
          });
          return this.fac.get(action.clientId, roadmapId);
        });
      }),
      map((action) =>
        RoadmapEntityActions.reloadAffectedRoadmapOKAction({
          data: action,
          message: 'Reloading roadmap results success!',
        }),
      ),
      catchError((err) =>
        of(
          RoadmapEntityActions.reloadAffectedRoadmapNOKAction({
            message:
              (err.error || {}).error || 'Reloading roadmap results error!',
          }),
        ),
      ),
    );
  }

  reloadAffectedRoadmapOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.reloadAffectedRoadmapOKAction),
    );
  }

  reloadAffectedRoadmapNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.reloadAffectedRoadmapNOKAction),
      tap((action) => this.toast.showToast(action.message, 'error')),
    );
  }

  // TODO: Remove queue failed attribute
  queueUpdateRoadmapSideEffect() {
    const unsubscribeSubject$ = new BehaviorSubject<string>('');
    let queueCount = 0;

    return unsubscribeSubject$.pipe(
      startWith(''),
      tap((roadmapId) => {
        queueCount = 0;
        if (roadmapId) {
          this.fac.updateQueueDetails(roadmapId, 0);
        }
      }),
      switchMap(() =>
        this.act.pipe(
          ofType(RoadmapEntityActions.queueUpdateRoadmapAction),
          tap(({ roadmapId }) =>
            this.fac.updateQueueDetails(roadmapId, (queueCount += 1)),
          ),
          concatMap((params) => {
            const { toBeUpdate, toBeDelete } = this.categorizeCampaigns(
              params.data,
            );

            let observables = [];
            if (toBeUpdate.length) {
              observables.push(
                this.fac.updateRoadmapCampaigns(
                  params.clientId,
                  params.roadmapId,
                  toBeUpdate,
                ),
              );
            }
            if (toBeDelete.length) {
              observables.push(
                this.fac.deleteRoadmapCampaigns(
                  params.clientId,
                  params.roadmapId,
                  toBeDelete,
                ),
              );
            }

            if (Coerce.toEmptyObject(params.settings).toBeUpdated) {
              observables.push(
                this.fac.updateRunSettings(
                  {
                    clientId: params.clientId,
                    roadmapId: params.roadmapId,
                    settings: params.settings,
                  },
                  false,
                ),
              );
            }

            return combineLatest(observables).pipe(
              tap((results) => {
                const isSuccess = results.every(Boolean);
                this.fac.updateQueueDetails(
                  params.roadmapId,
                  isSuccess ? (queueCount -= 1) : 0,
                  !isSuccess,
                );
              }),
              tap((results) => {
                const isSuccess = results.every(Boolean);
                !isSuccess && unsubscribeSubject$.next(params.roadmapId);
              }),
            );
          }),
        ),
      ),
    );
  }

  private categorizeCampaigns(data: RoadmapCampaign[]) {
    let toBeUpdate: RoadmapCampaign[] = [];
    let toBeDelete: RoadmapCampaign[] = [];
    data.forEach((campaign: RoadmapCampaign) => {
      if (campaign.isToBeDeleted) {
        toBeDelete.push({ id: campaign.id, type: campaign.rawType });
      } else {
        toBeUpdate.push(campaign);
      }
    });
    return { toBeUpdate, toBeDelete };
  }

  updateRunSettingsSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.updateRunSettingsAction),
      mergeMap((params) =>
        this.api.updateRunSettings(params.params).pipe(
          map((response) =>
            RoadmapEntityActions.updateRunSettingsOKAction({
              response,
              reloadAfter: params.reloadAfter,
            }),
          ),
          catchError(() =>
            of(RoadmapEntityActions.updateRunSettingsNOKAction(params.params)),
          ),
        ),
      ),
    );
  }

  updateRunSettingsOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.updateRunSettingsOKAction),
      tap((data) => {
        if (data.reloadAfter) {
          this.variationFacade.getList({
            clientId: data.response.clientId,
            roadmapId: data.response.id,
          });
          this.fac.fetchRoadmapCampaigns(
            data.response.clientId,
            data.response.id,
          );
        }
      }),
    );
  }

  updateRunSettingsNOKSideEffect() {
    return this.act.pipe(
      ofType(RoadmapEntityActions.updateRunSettingsNOKAction),
      tap((params) =>
        this.toast.showToast(
          'Update Run Settings Failed',
          'action',
          undefined,
          of({}).pipe(tap(() => this.fac.updateRunSettings(params))),
        ),
      ),
    );
  }

  get$ = createEffect(this.getRoadmapSideEffect.bind(this));
  getOK$ = createEffect(this.getRoadmapOKSideEffect.bind(this));
  getNOK$ = createEffect(this.getRoadmapNOKSideEffect.bind(this), {
    dispatch: false,
  });

  getAll$ = createEffect(this.getAllRoadmapSideEffect.bind(this));
  getAllOK$ = createEffect(this.getAllRoadmapOKSideEffect.bind(this), {
    dispatch: false,
  });
  getAllNOK$ = createEffect(this.getAllRoadmapNOKSideEffect.bind(this), {
    dispatch: false,
  });

  create$ = createEffect(this.createSideEffect.bind(this));
  createOK$ = createEffect(this.createOKSideEffect.bind(this));
  createNOK$ = createEffect(this.createNOKSideEffect.bind(this), {
    dispatch: false,
  });

  update$ = createEffect(this.updateSideEffect.bind(this));
  updateOK$ = createEffect(this.updateOKSideEffect.bind(this), {
    dispatch: false,
  });
  updateNOK$ = createEffect(this.updateNOKSideEffect.bind(this), {
    dispatch: false,
  });

  delete$ = createEffect(this.deleteSideEffect.bind(this));
  deleteOK$ = createEffect(this.deleteOKSideEffect.bind(this), {
    dispatch: false,
  });
  deleteNOK$ = createEffect(this.deleteNOKSideEffect.bind(this), {
    dispatch: false,
  });

  compute$ = createEffect(this.computeSideEffect.bind(this));
  computeOK$ = createEffect(this.computeOKSideEffect.bind(this));
  computeNOK$ = createEffect(this.computeNOKSideEffect.bind(this), {
    dispatch: false,
  });

  stopCompute$ = createEffect(this.stopComputeSideEffect.bind(this));
  stopComputeOK$ = createEffect(this.stopComputeOKSideEffect.bind(this), {
    dispatch: false,
  });
  stopComputePartial$ = createEffect(
    this.stopComputePartialSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  stopComputeNOK$ = createEffect(this.stopComputeNOKSideEffect.bind(this), {
    dispatch: false,
  });

  computeMultiple$ = createEffect(this.computeMultipleSideEffect.bind(this));
  computeMultipleOK$ = createEffect(
    this.computeMultipleOKSideEffect.bind(this),
    { dispatch: false },
  );
  computeMultipleNOK$ = createEffect(
    this.computeMultipleNOKSideEffect.bind(this),
    { dispatch: false },
  );

  setToBaseline$ = createEffect(this.setToBaselineSideEffect.bind(this));
  setToBaselineOK$ = createEffect(this.setToBaselineOKSideEffect.bind(this), {
    dispatch: false,
  });
  setToBaselineNOK$ = createEffect(this.setToBaselineNOKSideEffect.bind(this), {
    dispatch: false,
  });

  duplicateRoadmaps$ = createEffect(
    this.duplicateRoadmapsSideEffect.bind(this),
  );
  duplicateRoadmapsOK$ = createEffect(
    this.duplicateRoadmapsOKSideEffect.bind(this),
    { dispatch: false },
  );
  duplicateRoadmapsNOK$ = createEffect(
    this.duplicateRoadmapsNOKSideEffect.bind(this),
    { dispatch: false },
  );

  reloadSingleRoadmap$ = createEffect(
    this.reloadSingleRoadmapSideEffect.bind(this),
  );
  reloadSingleRoadmapOK$ = createEffect(
    this.reloadSingleRoadmapOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  reloadSingleRoadmapNOK$ = createEffect(
    this.reloadSingleRoadmapNOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );

  computeCanLaunchRoadmap$ = createEffect(
    this.computeCanLaunchRoadmapSideEffect.bind(this),
  );

  reloadMultipleRoadmap$ = createEffect(
    this.reloadMultipleRoadmapSideEffect.bind(this),
  );
  reloadMultipleRoadmapOK$ = createEffect(
    this.reloadMultipleRoadmapOKSideEffect.bind(this),
    { dispatch: false },
  );
  reloadMultipleRoadmapNOK$ = createEffect(
    this.reloadMultipleRoadmapNOKSideEffect.bind(this),
    { dispatch: false },
  );

  reloadAffectedRoadmap$ = createEffect(
    this.reloadAffectedRoadmapSideEffect.bind(this),
  );
  reloadAffectedRoadmapOK$ = createEffect(
    this.reloadAffectedRoadmapOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );
  reloadAffectedRoadmapNOK$ = createEffect(
    this.reloadAffectedRoadmapNOKSideEffect.bind(this),
    {
      dispatch: false,
    },
  );

  queueUpdateRoadmap$ = createEffect(
    this.queueUpdateRoadmapSideEffect.bind(this),
    { dispatch: false },
  );

  updateRunSettings$ = createEffect(
    this.updateRunSettingsSideEffect.bind(this),
  );
  updateRunSettingsOK$ = createEffect(
    this.updateRunSettingsOKSideEffect.bind(this),
    { dispatch: false },
  );
  updateRunSettingsNOK$ = createEffect(
    this.updateRunSettingsNOKSideEffect.bind(this),
    { dispatch: false },
  );

  fetchRoadmapCampaigns$ = createEffect(() =>
    this.act.pipe(
      ofType(RoadmapEntityActions.fetchRoadmapCampaigns),
      mergeMap((action) =>
        this.api.fetchRoadmapCampaigns(action.clientId, action.roadmapId).pipe(
          map((data: Array<RoadmapCampaign>) =>
            RoadmapEntityActions.fetchRoadmapCampaignsOK({
              data,
              roadmapId: action.roadmapId,
            }),
          ),
          catchError(() =>
            of(RoadmapEntityActions.fetchRoadmapCampaignsNOK(action)),
          ),
        ),
      ),
    ),
  );

  updateRoadmapCampaigns$ = createEffect(() =>
    this.act.pipe(
      ofType(RoadmapEntityActions.updateRoadmapCampaigns),
      mergeMap((action) =>
        this.api
          .updateRoadmapCampaigns(
            action.clientId,
            action.roadmapId,
            action.campaigns,
          )
          .pipe(
            map((data: PortfolioRoadmap) =>
              RoadmapEntityActions.updateRoadmapCampaignsOK({
                roadmap: data,
                campaigns: action.campaigns,
              }),
            ),
            catchError(() =>
              of(RoadmapEntityActions.updateRoadmapCampaignsNOK(action)),
            ),
          ),
      ),
    ),
  );

  deleteRoadmapCampaigns$ = createEffect(() =>
    this.act.pipe(
      ofType(RoadmapEntityActions.deleteRoadmapCampaigns),
      mergeMap((action) =>
        this.api
          .deleteRoadmapCampaigns(
            action.clientId,
            action.roadmapId,
            action.campaigns,
          )
          .pipe(
            map((response) => {
              return RoadmapEntityActions.deleteRoadmapCampaignsOK({
                roadmap: response as PortfolioRoadmap,
                campaigns: action.campaigns,
              });
            }),
            catchError(() =>
              of(RoadmapEntityActions.deleteRoadmapCampaignsNOK(action)),
            ),
          ),
      ),
    ),
  );
}
