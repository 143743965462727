import { ClientDataEntity } from 'ssotool-app/+client';
import {
  BaseCurveEntity,
  CampaignDetails,
  CurveData,
  GeographyDetails,
  ProjectDetails,
} from 'ssotool-app/+roadmap/stores/result/result.model';
import { NAN_VALUE } from 'ssotool-app/app.references';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';
import { Coerce, YearlyValues } from 'ssotool-shared';

import { Injectable } from '@angular/core';

import { Site } from '../../../+client/store/client.model';
import {
  CAMPAIGN_VIEW,
  DetailDataParameters,
  GEOGRAPHY_VIEW,
} from './result-utility.model';
import { groupBy } from 'lodash';

type DetailedCurvesRecord = Record<string, CampaignDetails | GeographyDetails>;
type CurveDataEntry = [string, BaseCurveEntity[]];

@Injectable()
export class ResultParserService {
  get isInputSimplified(): boolean {
    return isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE);
  }

  groupDetailedProjects(
    projects: ProjectDetails[],
    requiredFields: string[],
  ): Record<string, ProjectDetails> {
    return Coerce.toArray(requiredFields).reduce((groupedProjects, kpi) => {
      projects?.forEach((project) => {
        const key = `${project.campaignId}_${this.isInputSimplified ? project.siteId : project.geoId
          }_${project.startDate}`;
        if (groupedProjects?.[key]) {
          groupedProjects[key][kpi] += project?.[kpi] || 0;
        } else {
          groupedProjects[key] = project;
        }
      });

      return groupedProjects;
    }, {});
  }

  groupDetailedCurves(
    curveData: CurveData,
    params: DetailDataParameters,
  ): DetailedCurvesRecord {
    const details: DetailedCurvesRecord = {};

    Object.entries(curveData).forEach((curveDatum) => {
      if (params.group === GEOGRAPHY_VIEW) {
        this.groupDetailedCurveByGeography(details, curveDatum, params);
      } else if (params.group === CAMPAIGN_VIEW) {
        this.groupDetailedCurveByCampaign(details, curveDatum, params);
      }
    });

    return details;
  }

  /**
   * This function groups detailed curve data by geography view.
   * @param details - The record of grouped data.
   * @param [kpi, kpiData] - The array of kpi and its data.
   * @param params - The parameters of the data.
   * @returns void
   */
  private groupDetailedCurveByGeography(
    details: DetailedCurvesRecord,
    [kpi, kpiData]: CurveDataEntry,
    {
      viewLevels,
      geographyNameLevelMap,
      geographyLevelNameMap,
      geographyHierarchyMap,
      level,
      geos,
      sites,
    }: DetailDataParameters,
  ) {
    const selectedHid = geographyNameLevelMap[viewLevels[1]];
    const filteredKpiData = kpiData.filter((datum) => {
      return datum?.geoHid?.startsWith(selectedHid);
    });

    switch (viewLevels?.length) {
      case 2: {
        this.groupDataByCampaign(
          details,
          filteredKpiData,
          kpi,
          GEOGRAPHY_VIEW,
        );
        break;
      }
      default:
        this.groupDataByGeography(
          details,
          kpiData,
          geographyLevelNameMap,
          geographyHierarchyMap,
          kpi,
          level,
          geos,
          sites,
        );
    }

  }

  private groupDetailedCurveByCampaign(
    details: DetailedCurvesRecord,
    [kpi, kpiData]: CurveDataEntry,
    { viewLevels, geographyHierarchyMap }: DetailDataParameters,
  ) {
    switch (viewLevels?.length) {
      case 2:
        this.groupDataByCampaign(
          details,
          kpiData.filter((datum) => datum.campaign === viewLevels[1]),
          kpi,
          GEOGRAPHY_VIEW,
        );
        break;
      default:
        this.groupDataByCampaign(details, kpiData, kpi);
        this.convertGeoToParent(details, geographyHierarchyMap);
    }
  }

  private groupDataByCampaign(
    details: DetailedCurvesRecord,
    kpiData: BaseCurveEntity[],
    kpi: string,
    additionalUniquenessKey = '',
  ) {
    kpiData.forEach((kpiDetails) => {
      const detail_key = `${kpiDetails?.campaign}_${kpiDetails?.lever}_${kpiDetails?.campaignCategory
        }_${kpiDetails?.process}${additionalUniquenessKey
          ? `_${kpiDetails?.[additionalUniquenessKey]}`
          : ''
        }`;

      if (!details?.[detail_key]) {
        details[detail_key] = {
          name: kpiDetails?.campaign,
          type: kpiDetails.lever,
          category: kpiDetails.campaignCategory,
          process: kpiDetails.process,
          geography: kpiDetails.geography,
          geoHids: [],
        };
      }

      details[detail_key].geoHids.push(kpiDetails.geoHid);

      details[detail_key][kpi] = this.aggregateValues(
        details[detail_key]?.[kpi],
        kpiDetails.values,
      );
    });
  }

  /**
   * Groups curves by geography. If the input is simplified, then the curves will be grouped by
   * site. Otherwise, the curves will be grouped by geography level.
   * @param details - Record of grouped data.
   * @param kpiData - Array of kpi and its data.
   * @param geographyLevels - Record of hierarchy levels.
   * @param hierarchyMap - Record of hierarchy map.
   * @param kpi - kpi name.
   * @param level - level name.
   * @param geographies - Client data entity of geographies.
   * @param sites - Client data entity of sites.
   */
  private groupDataByGeography(
    details: DetailedCurvesRecord,
    kpiData: BaseCurveEntity[],
    geographyLevels: Record<string, string>,
    hierarchyMap: Record<string, string>,
    kpi: string,
    level: string,
    geographies: ClientDataEntity,
    sites: ClientDataEntity<Site>,
  ): void {
    if (level == 'Site') {
      kpiData.forEach((kpiDetails) => {
        this.groupDataBySite(sites, details, kpi, level, kpiDetails);
      });
    } else {
      this.groupDataByWorld(geographyLevels, details, kpi, level, kpiData);
    }
  }

  /**
   * Groups curves by world level. This function is used when the input is
   * simplified and the hierarchy levels is not empty.
   * @param hierarchyMap - Record of hierarchy map.
   * @param details - Record of grouped data.
   * @param kpi - kpi name.
   * @param level - level name.
   * @param kpiDetails - Base curve entity.
   */
  private groupDataByWorld(
    hierarchyMap: Record<string, string>,
    details: DetailedCurvesRecord,
    kpi: string,
    level: string,
    kpiDetails: BaseCurveEntity[],
  ): void {
    const groupedByGeography = groupBy(kpiDetails, 'geoHid');
    Object.entries(hierarchyMap).forEach(([hId, geography]) => {
      const childrenHid = Object.keys(groupedByGeography).filter((hid) => hid.startsWith(hId));
      if (!details[geography]) {
        details[geography] = {
          name: geography,
          type: level,
          process: kpiDetails[0].process,
          geoParent: hierarchyMap[hId],
        };
      }

      childrenHid.forEach((hid) => {
        const kpiDetailsSubgroup = groupedByGeography[hid];
        kpiDetailsSubgroup.forEach((kpiDetails) => {
          if (!details[geography][kpi]) {
            details[geography][kpi] = {};
          }

          details[geography][kpi] = this.aggregateValues(
            details[geography]?.[kpi],
            kpiDetails.values,
          );
        });
      });
    });
  }

  private determineGeoParent(
    name: string,
    hIdToLookFor: string,
    hierarchyMap: Record<string, string>,
  ) {
    if (name === 'World') {
      return '';
    } else {
      return hierarchyMap[hIdToLookFor] || hierarchyMap['000'];
    }
  }

  /**
   * Groups curves by site level. This function is used when the input is
   * simplified and the hierarchy levels is empty.
   * @param sites - Record of site data.
   * @param details - Record of grouped data.
   * @param kpi - kpi name.
   * @param level - level name.
   * @param kpiDetails - Base curve entity.
   */
  private groupDataBySite(
    sites: ClientDataEntity<Site>,
    details: DetailedCurvesRecord,
    kpi: string,
    level: string,
    kpiDetails: BaseCurveEntity,
  ): void {
    Coerce.getObjValues(sites).forEach((site) => {
      if (kpiDetails?.site === site.name) {
        const detail_key = `${kpiDetails?.site}`;
        if (!details?.[detail_key]) {
          details[detail_key] = {
            name: site.name,
            type: level,
            process: kpiDetails.process,
            geoParent: kpiDetails.geoCountry,
          };
        }
        details[detail_key][kpi] = this.aggregateValues(
          details[detail_key]?.[kpi],
          kpiDetails?.values,
        );
      }
    });
  }

  private aggregateValues(
    existingValues: YearlyValues,
    nextValues: YearlyValues,
  ): YearlyValues {
    return Object.entries(nextValues).reduce<YearlyValues>(
      (yearlyValues, [year, value]) => {
        value = value === NAN_VALUE ? '0' : value;

        if (existingValues && existingValues?.[year]) {
          yearlyValues[year] = (
            Number(existingValues?.[year]) + (Number(value) || 0)
          ).toString();
        } else {
          yearlyValues[year] = value;
        }
        return yearlyValues;
      },
      {},
    );
  }

  private convertGeoToParent(
    details: DetailedCurvesRecord,
    hierarchyMap: Record<string, string>,
  ) {
    Object.entries(details).forEach(([key, detail]) => {
      const hidParts: string[][] = detail?.geoHids?.reduce((acc, hid) => {
        hid?.split('-').forEach((value, index) => {
          if (!acc[index]) {
            acc[index] = [value];
          } else {
            acc[index].push(value);
          }
        });
        return acc;
      }, []);

      let parentHid: string;

      hidParts.every((part) => {
        const isPartEqual = new Set(part).size === 1;

        if (isPartEqual) {
          if (!parentHid) {
            parentHid = part[0];
          } else {
            parentHid += `-${part[0]}`;
          }

          return isPartEqual;
        } else {
          return false;
        }
      });

      if (parentHid) {
        details[key].geography = hierarchyMap[parentHid];
      }
    });
  }
}
